<template>
    <div class="project-card" :class="align">
        <div class="project-img">
            <img :src="img" :alt="alt" />
        </div>
        <div class="project-text">
            <h2 class="title">
                <slot name="title"></slot>
            </h2>
            <p class="client">
                <i><slot name="client"></slot></i>
            </p>
            <p class="desc">
                <slot name="description"></slot>
            </p>
        </div>
        <a :href="link" class="project-link" target="_blank" rel="preload">
            Visit website
        </a>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        align: {
            type: String,
            default: 'left'
        },
        img: {
            type: String,
            default: 'img/unknown.png'
        },
        alt: {
            type: String,
            default: 'Screenshot of project.'
        },
        link: {
            type: String,
            default: '#'
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/common.scss";

.project-card {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "img text"
        "img link";
    gap: 18px;

    margin-left: auto;
    margin-right: auto;

    padding: 20px;

    width: 760px;
    height: fit-content;
    min-height: 200px;

    color: white;
    background-color: $box-bg-color-dark;
    backdrop-filter: blur($blur);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

    border-radius: $small-border-radius;

    animation: fadeIn 1s ease-out forwards;
}

.project-card.right {
    grid-template-areas:
        "text img"
        "link img";
}

.project-img {
    grid-area: img;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
}

.project-img img {
    border-radius: $small-border-radius;

    width: 350px;
    max-width: 100%;

    height: auto;
}

.project-text {
    grid-area: text;
    text-align: left;
    height: 100%;
}

.project-text .title {
    line-height: 1;
}

.project-text .desc {
    grid-area: text;
    margin-top: 10px;
}

.project-link {
    display: inline-block;
    grid-area: link;

    width: 100%;

    margin-top: auto;
    padding: 0.5em 1em;

    /*background-color: rgba(255, 255, 255, 0.1);*/
    background-color: rgba(0, 0, 0, 0.33);
    color: white;

    font-size: 1.2em;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    text-transform: lowercase;

    border-radius: $small-border-radius;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

    transition: all 0.1s;
}

.project-link:hover {
    /*background-color: rgba(255, 255, 255, 0.2);*/
    transform: scale(1.01);
}

/* Mobile */
@media (max-width: $mobile-width) {
    .project-card {
        grid-template-columns: 1fr;
        grid-template-areas:
            "img"
            "text"
            "link" !important;

        width: 100%;
        max-width: 500px;
    }

    .project-img img {
        width: 100%;
    }
}
</style>
