<template comments>
    <a class="contact-email" :href="emailLink">contact<!-- asdk;jl]W2nnwelw -->&commat;<!-- ;uheq8iw.;beaspe -->zac<!-- 29nd-vuv187aBSX/ -->&#46;boston</a>
</template>

<script>
export default {
    data() {
        return {
            email: "Y29udGFjdEB6YWMuYm9zdG9u"
        };
    },
    computed: {
        emailLink() {
            const decodedEmail = atob(this.email);
            return "mailto:" + decodedEmail;
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/common.scss";

.contact-email {
    margin: 10px 0;

    color: $accent-color;
    font-size: 1.5em;
    text-decoration: none;

    text-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
}

.contact-email:hover {
    text-decoration: underline;
}
</style>
