<template>
  <canvas id="skyCanvas" width="800" height="600"></canvas>
</template>

<script>
export default {
  name: 'SkyCanvas',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'night'
    }
  },
  mounted() {
    if (!this.disabled) {
      /*if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        mode = 'night';
      }*/ // todo implement light/dark theme switching

      render(this.mode);
    }
  }
}

function normalRandom() {
    let u = 0, v = 0;
    while (u === 0) u = Math.random(); // Converting [0,1) to (0,1)
    while (v === 0) v = Math.random();
    return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
}

/**
 * @class RNG
 *
 * @brief A seeded random number generator.
 */
 class RNG {
    static seed;

    constructor(seed) {
        this.seed = seed;
    }

    nextFloat() {
        // LCG parameters from Numerical Recipes
        const m = Math.pow(2, 32);
        const a = 1664525;
        const c = 1013904223;

        this.seed = (a * this.seed + c) % m;
        return this.seed / m;
    }

    setSeed(seed) {
        this.seed = seed;
    }

    getSeed() {
        return this.seed;
    }
}

/**
 * Cloud class
 * Cloud is comprised of a large collection of small overlapping circles.
 * Circle positions are psuedorandomly generated.
 */
class Cloud {
  static minCircleRadius = 10;
  static maxCircleRadius = 20;

  static minCircleOpacity = 0.0;
  static maxCircleOpacity = 0.1;

  static minCircles = 200;
  static maxCircles = 300;

  static minWidth = 300;
  static maxWidth = 800;

  static minHeight = 100;
  static maxHeight = 150;

  static circleSpreadFactor = 0.5;

  constructor(x, y) {
    this.x = x;
    this.y = y;

    this.dx = 0.05;
    this.dy = 0;

    this.numCircles = Math.floor(Math.random() * (Cloud.maxCircles - Cloud.minCircles) + Cloud.minCircles);

    this.width = Math.random() * (Cloud.maxWidth - Cloud.minWidth) + Cloud.minWidth;
    this.height = Math.random() * (Cloud.maxHeight - Cloud.minHeight) + Cloud.minHeight;

    this.circles = [];
    this.generateCircles();
  }

  generateCircles() {
    const spreadFactor = Cloud.circleSpreadFactor;

    for (let i = 0; i < this.numCircles; i++) {
      let radius = Math.random() * (Cloud.maxCircleRadius - Cloud.minCircleRadius) + Cloud.minCircleRadius;

      let x = normalRandom() * (this.width - this.width / 2) * spreadFactor;
      let y = normalRandom() * (this.height - this.height / 2) * spreadFactor;
      let dx = 0;
      let dy = 0;

      if (i > 10) {
        // keep trying Xs and Ys until the circle overlaps with at least one other circle
        let overlap = false;
        while (!overlap) {
          overlap = this.circles.some(circle => {
            let dx = circle.x - x;
            let dy = circle.y - y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            return distance < circle.radius + radius;
          });

          if (!overlap) {
            x = normalRandom() * (this.width - this.width / 2) * spreadFactor;
            y = normalRandom() * (this.height - this.height / 2) * spreadFactor;
          }
        }
      }

      let opacity = Math.random() * (Cloud.maxCircleOpacity - Cloud.minCircleOpacity) + Cloud.minCircleOpacity;
      this.circles.push({ x, y, dx, dy, radius, opacity });

      // set opacity to 0
      let circIndex = this.circles.length - 1;
      let circle = this.circles[circIndex];
      circle.opacity = 0;

      // fade in the circle
      const fade_dur_ms = 7000;
      const fade_delay_range_ms = 3000;


      setTimeout(() => {
        let fadeInInterval = setInterval(() => {
          circle.opacity += 0.01;
          if (circle.opacity >= opacity) {
            clearInterval(fadeInInterval);
          }
        }, fade_dur_ms / 60);
      }, Math.random() * fade_delay_range_ms + (circIndex * (fade_dur_ms / 2) / this.numCircles));
    }
  }

  update(mousex, mousey, mousedx, mousedy) {
    // move the cloud
    this.x += this.dx * 2;

    // move the circles
    this.circles.forEach(circle => {
      // randomly shift the position
      circle.dx += normalRandom() * 0.1;
      circle.dy += normalRandom() * 0.1;

      // if mouse touching the circle, move it away
      let dx = this.x + circle.x - mousex;
      let dy = this.y + circle.y - mousey;
      let distance = Math.sqrt(dx * dx + dy * dy);
      if (distance < circle.radius * 1.5) {
        circle.dx += mousedx * 0.1;
        circle.dy += mousedy * 0.1;
      }

      // move the circle
      circle.x += circle.dx;
      circle.y += circle.dy;

      circle.dx *= 0.66;
      circle.dy *= 0.66;
    });

  }

  draw(ctx) {
    this.circles.forEach(circle => {
      ctx.fillStyle = 'rgba(74, 74, 84, ' + circle.opacity + ')';
      ctx.beginPath();
      ctx.arc(this.x + circle.x, this.y + circle.y, circle.radius, 0, Math.PI * 2);
      ctx.fill();

      // draw a shadow
      /*ctx.fillStyle = 'rgba(0, 0, 0, ' + 0.025 + ')';
      ctx.beginPath();
      ctx.arc(this.x + circle.x + 2, this.y + circle.y + 2, circle.radius, 0, Math.PI * 2);
      ctx.fill();*/
    });
  }
}

let mousex = 0;
let mousey = 0;
let mousedx = 0;
let mousedy = 0;
function render(mode) {
  // resize the canvas to fill browser window dynamically
  window.addEventListener('resize', resizeCanvas, false);
  resizeCanvas();

    window.addEventListener('mousemove', e => {
      mousedx = e.clientX - mousex;
      mousedy = e.clientY - mousey;
      mousex = e.clientX;
      mousey = e.clientY;
    });

  let clouds = [];
  let numClouds = 6;
  for (let i = 0; i < numClouds; i++) {
    let x = Math.random() * window.innerWidth;
    let y = Math.random() * window.innerHeight;
    clouds.push(new Cloud(x, y));
  }
  setInterval(() => {
    updateClouds(clouds, mousex, mousey, mousedx, mousedy);
    renderBg(mode);
    renderClouds(clouds);
  }, 1000 / 30);
}

function resizeCanvas() {
  const canvas = document.getElementById('skyCanvas');
  // get element containing canvas
  const container = canvas.parentElement;
  canvas.width = container.clientWidth;
  canvas.height = container.clientHeight;
  renderBg();
}

function renderBg(mode) {
  const canvas = document.getElementById('skyCanvas');
  const ctx = canvas.getContext('2d');

  let width = canvas.width;
  let height = canvas.height;

  // day bg: radial-gradient(300% 250% at 0 150%, #fca945, #49b8f4 45%, #1d7bff 70%);
  // night bg: radial-gradient(300% 250% at 0 150%, #0e0922, #0d1724 45%, #020710 70%)

  let gradient = ctx.createRadialGradient(0, height * 1.5, 100, 100, height, width);
  if (mode === 'night') {
    gradient.addColorStop(0, '#0e0922');
    gradient.addColorStop(0.45, '#0d1724');
    gradient.addColorStop(0.99, '#020710');
  } else if (mode === 'day') {
    gradient.addColorStop(0, '#fca945');
    gradient.addColorStop(0.45, '#49b8f4');
    gradient.addColorStop(0.99, '#1d7bff');
  } else {
    gradient.addColorStop(0, '#000');
  }

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, width, height);

  if (mode === 'night') {
    renderStars(ctx, width, height);
  }
}

function renderStars(ctx, width, height) {
  let rng = new RNG(1234);

  let numStars = 100;
  for (let i = 0; i < numStars; i++) {
    let x = rng.nextFloat() * width;
    let y = rng.nextFloat() * height;
    let radius = rng.nextFloat() * 2;
    ctx.fillStyle = 'rgba(255, 255, 255, ' + rng.nextFloat() + ')';
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2);
    ctx.fill();
  }
}

function updateClouds(clouds, mousex, mousey, mousedx, mousedy) {
  clouds.forEach(cloud => {
    cloud.update(mousex, mousey, mousedx, mousedy);

    // check if cloud is offscreen
    if (cloud.x - cloud.width > window.innerWidth) {
      cloud.x = -cloud.width;
      cloud.y = Math.random() * window.innerHeight;
      cloud.generateCircles();
    }
  });
}

function renderClouds(clouds) {
  const canvas = document.getElementById('skyCanvas');
  const ctx = canvas.getContext('2d');

  clouds.forEach(cloud => {
    cloud.draw(ctx);
  });
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/common.scss";

#skyCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(1px);
  z-index: -1;
  transform: scale(1.05);
  animation: fadeIn 2s ease-out forwards; /* Adjust the duration (2s) as needed */
}
</style>
