<template>
  <nav class="main-nav">
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <!--<li><a href="#about">About</a></li>-->
      <li><router-link to="/portfolio">Portfolio</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MainNav'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/common.scss";

.main-nav {
  grid-area: nav;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 3em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  padding: 0 40px;

  background-color: $box-bg-color-dark;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  backdrop-filter: blur($blur);
}

.main-nav ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.main-nav li {
  margin: 0 10px;
  padding: 2px 15px;

  border-radius: 0.5em;
  transition: background-color 0.2s;
}

.main-nav a {
  color: white;
  text-decoration: none;
  font-size: 1em;
  text-transform: lowercase;
  letter-spacing: 1px;
}

.main-nav li:hover {
  background-color: rgba(255, 255, 255, 0.10);
}

.main-nav li:active {
  background-color: rgba(255, 255, 255, 0.08);
}

/* Mobile */
@media (max-width: $mobile-width) {
  .main-nav {
    /*width: 95%;
    max-width: 450px !important;*/
    padding: 0;
  }

}
</style>
