<template>
    <div class="portfolio">
        <div class="portfolio-projects">
            <ProjectCard align="right" img="img/sc/dragboston.png" alt="Screenshot of Drag Events website." link="https://lucillexoxo.com/">
                <template v-slot:title>
                    Drag Events with Lucille + Violencia
                </template>
                <template v-slot:client>
                    Lucille & Violencia Exclamation Point
                </template>
                <template v-slot:description>
                    A website for booking events with Lucille & Violencia, a drag queen duo based in Boston, MA.
                </template>
            </ProjectCard>
            <ProjectCard align="left" img="img/sc/bentley.png" alt="Screenshot of Bentley Bot website." link="https://bentley.slymods.info/">
                <template v-slot:title>
                    Bentley Bot for Discord
                </template>
                <template v-slot:client>
                    SlyMods
                </template>
                <template v-slot:description>
                    The website for Bentley, a Discord chat bot that uses AI to chat with server members.
                </template>
            </ProjectCard>
            <ProjectCard align="right" img="img/sc/rc-clock.png" alt="Screenshot of RC Clock website." link="https://clock.zacbox.app/">
                <template v-slot:title>
                    RC Clock
                </template>
                <template v-slot:client>
                    The Bay Players of Duxbury
                </template>
                <template v-slot:description>
                    A web app featuring an analog clock, for use in theater productions or classroom/<wbr />office settings.
                </template>
            </ProjectCard>
            <ProjectCard align="left" img="img/sc/zacbox.png" alt="Screenshot of Zacbox website." link="https://zacbox.app/">
                <template v-slot:title>
                    Zacbox
                </template>
                <template v-slot:client>
                    Personal project
                </template>
                <template v-slot:description>
                    A collection of web apps by me, including games, utilities, and data visualizations.
                </template>
            </ProjectCard>
        </div>
    </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue';

export default {
    name: 'PortfolioView',
    components: {
        ProjectCard
    }
};
</script>

<style scoped lang="scss">
.portfolio {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "header"
        "projects";

    padding: 0 18px;
    margin: 20px 0px;

    width: 100%;
    max-width: 1024px;

    color: white;
}

.portfolio-title {
    grid-area: header;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
    font-size: 1.5em;
    text-align: center;
}

.portfolio-projects {
    grid-area: projects;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;

    height: 100%;
    width: 100%;
}

.project-card {
    margin: 10px 0px;
}
</style>
