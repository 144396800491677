<template>
    <div class="contact">
        <h1>Contact Me</h1>
        <p>Say hello if you need a website for yourself, your business, organization, or project.</p>
        <ContactEmail />
    </div>
</template>

<script>
import ContactEmail from '@/components/ContactEmail.vue';

export default {
    name: 'ContactView',
    components: {
        ContactEmail,
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/common.scss";

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 80px;

    width: 90%;
    height: 100%;

    animation: fadeIn 1s ease-out forwards;
}

.contact h1 {
    font-size: 3em;
}

.contact p {
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 18px 0 20px 0;
}

@media (max-width: $mobile-width) {
    .contact {
        padding: 0 40px;
    }

    .contact h1 {
        font-size: 2em;
    }

    .contact p {
        font-size: 1.3em;
        line-height: 1.1em;
    }

    .contact a {
        font-size: 1.3em;
        margin: 20px 0;
    }
}

</style>
