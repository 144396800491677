<template>
  <SkyCanvas mode="night"/>
  <PageFrame />
  <MainNav />
  <router-view id="content"></router-view>
</template>

<script>
import SkyCanvas from './components/SkyCanvas.vue';
import MainNav from './components/MainNav.vue';

export default {
  name: 'App',
  components: {
    SkyCanvas,
    MainNav
  }
}
</script>

<style lang="scss">
@import "@/assets/common.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Playwrite+IS:wght@100..400&display=swap');

html, body, body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  /*background: radial-gradient(300% 250% at 0 150%, #fca945, #49b8f4 30%, #1d7bff 50%);*/
  /*border: 15px solid white;
  border-radius: 10px;*/
}

html {
  background: #fff;
}

@media (prefers-color-scheme: dark) {
  html {
    background: #000;
  }
}

#app {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav"
    "content";

  width: 100%;
  height: 100%;

  font-family: "Inter Tight", "Helvetica Neue", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $fg-color;

  overflow-y: scroll;
}

#content {
  grid-area: content;
}

#content {
  margin-left: auto;
  margin-right: auto;
}
</style>
