<template>
  <div class="bio-card">
    <div class="bio-portrait">
        <img id="portrait" src="img/memoji.png" alt="Memoji portrait of Zac Krasnow."/>
    </div>
    <div class="bio-text">
        <h1 class="bio-title">Hi, I'm Zac.</h1>
        <p class="bio-subtitle">I'm a web developer based in Boston, MA. I build responsive websites with modern designs.
        </p>
        <!--<div class="bio-tags">
            <div class="tag">HTML</div>
            <div class="tag">CSS</div>
            <div class="tag">Javascript</div>
            <div class="tag">Typescript</div>
            <div class="tag">Node.js</div>
            <div class="tag">Express</div>
            <div class="tag">React</div>
            <div class="tag">Vue</div>
            <div class="tag">Websockets</div>
            <div class="tag">APIs</div>
        </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BioCard',
  mounted() {
    animateIn();
  }
}

function animateIn() {
    const bioPortrait = document.getElementsByClassName('bio-portrait')[0];
    bioPortrait.style.opacity = 0;

    const bioTitle = document.getElementsByClassName('bio-title')[0];
    const bioSubtitle = document.getElementsByClassName('bio-subtitle')[0];
    bioTitle.innerHTML = '';
    bioSubtitle.innerHTML = '';

    let strings = [
        {
            element: bioTitle,
            text: "Hi,",
            dur: 200,
            timeout: 200,
        },
        {
            element: bioTitle,
            text: " I'm Zac.",
            dur: 550,
            timeout: 1000,
        },
        {
            element: bioSubtitle,
            text: "I'm a web developer based in Boston, MA.",
            dur: 1500,
            timeout: 500,
        },
        {
            element: bioSubtitle,
            text: " I build responsive websites with modern designs.",
            dur: 1500,
            timeout: 0,
        }
    ]

    let portraitDelay = 500;
    setTimeout(() => {
        fadeIn(bioPortrait, 1000);
    }, portraitDelay);

    let textDelay = 1500;
    setTimeout(() => {
        let runtime = 0;
        strings.forEach(item => {
            setTimeout(() => {
                typeIn(item.element, item.text, item.dur);
            }, runtime)
            runtime += item.dur + item.timeout
        });
    }, textDelay);
}

function typeIn(element, text, dur_ms=2000) {
    const len = text.length;
    const ms_per_ch = dur_ms / len;
    for (let i = 0; i < len; i++) {
        const ch = text[i];
        setTimeout(() => {
            element.innerHTML += ch;
        }, i * ms_per_ch);
    }
}

function fadeIn(element, dur_ms) {
    const step = 1 / dur_ms;
    for (let i = 0; i < dur_ms; i++) {
        setTimeout(() => {
            element.style.opacity = parseFloat(element.style.opacity) + step;
        }, i);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/common.scss";

$opacity: 0.8;

.bio-card {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "portrait text";
  gap: 18px;

  width: 100%;
  max-width: 540px;

  color: white;

  border-radius: $small-border-radius;
  padding: 5px 10px;
}

.bio-portrait {
    display: flex;
    justify-content: center;
    align-items: center;

    grid-area: portrait;

    margin-left: auto;
    margin-right: auto;

    position: relative;
    flex-shrink: 0;
    width: 9em;
    height: 9em;

    /* day */
    //background: radial-gradient(circle at center, #f3f5ed, #c7e2e0);

    /* night */
    background: radial-gradient(circle at center, #130c2e, #0f1b2a);

    background-size: contain;
    background-repeat: no-repeat;
    border-radius: inherit;

    border-radius: $big-border-radius;
    box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.2);

    transform: scale(1) rotate(0deg);
    opacity: 1;

    /*-webkit-transition: transform 0.3s ease-in-out, opacity 0.2s ease-in;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in; */

    -webkit-user-select: none;
    user-select: none;

    overflow: hidden;
}

/* @media (prefers-color-scheme: dark) {
    .bio-portrait {
        background: radial-gradient(circle at center, #130c2e, #0f1b2a);
    }
} */

.bio-portrait img {
    width: 100%;
    border-radius: inherit;

    -webkit-user-drag: none;

    -webkit-filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.3));
}

/* .bio-portrait:hover {
    transform: scale(1.01) rotate(1deg);
}

.bio-portrait:active {
    transform: scale(0.8) rotate(22.5deg);
    opacity: 0;
} */

.bio-text {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;
    text-align: left;

    height: 101px;
    margin-top: auto;
    margin-bottom: auto;

    font-size: 150%;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}

.bio-title, .bio-subtitle {
    font-family: "Inter Tight", "Helvetica Neue", sans-serif;
}

.bio-title {
    font-size: 50px;
}

.bio-subtitle {
    font-size: 17px;
    text-align: lft;
}

.bio-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: -4px;

    justify-content: left;

    font-size: 14px;
}

.tag {
    display: inline-block;

    margin: 4px;
    padding: 0.2em 0.6em;

    font-family: "Jura", sans-serif;
    text-align: center;
    font-size: 0.9em;

    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);

    -webkit-user-drag: element;
    -webkit-user-select: none;
    user-select: none;
}

/* Mobile */
@media (max-width: $mobile-width) {
  .bio-card {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "portrait"
        "text";
  }

  .bio-title {
    font-size: 40px;
    width: 100%;
  }

  .bio-subtitle {
    font-size: 18px;
    width: 100%;
  }

  .bio-title, .bio-subtitle {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

}
</style>
