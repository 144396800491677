<template>
    <div>
        <div id="hero">
            <BioCard />
        </div>
    </div>
</template>

<script>
import BioCard from '@/components/BioCard.vue';

export default {
    name: 'HomeView',
    components: {
        BioCard
    }
};
</script>

<style scoped lang="scss">
#hero {
    grid-area: content;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 100%;
    height: 100%;

    user-select: none;
    padding-bottom: 5%;
}

.hero-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    grid-area: hero-title;

    width: 100%;
    height: 100%;
}

.hero-title h1 {
    color: white;
    font-size: 4em;
    line-height: 0.7;
    font-family: "Playfair Display", serif;
}

.hero-title h2 {
    color: white;
    font-size: 2em;
    font-family: "Playwrite IS", serif;
}

.hero-title,
.hero-subtitle {
    /*text-shadow: 0 0 13px rgb(0 0 0 / 80%);*/
    $stroke-1: rgba(0, 0, 0, 0.33);
    $stroke-2a: rgba(73, 184, 244, 1);
    $stroke-2b: rgba(29, 123, 255, 1);
    $stroke-blend: rgb(51, 154, 250);
    $black: #000;
    $white: #fff;
    $w: 1px;
    text-shadow:
        (-$w) (-$w) $w $stroke-1,
        $w (
        -$w) $w $stroke-1,
        (-$w) $w $w $stroke-1,
        $w $w $w $stroke-1,
}

.hero-bio {
    grid-area: bio;

    display: grid;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;

    width: 100%;
    height: 100%;
}

@media (max-width: 630px) {
    .hero-title {
    };
}
</style>
